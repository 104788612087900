import richTextBoxCompsToPackages from './legacy/components/RichTextBox/compsToPackages';
import sliderCompsToPackages from './components/Slider/compsToPackages';
import searchBoxCompsToPackages from './components/SearchBox/compsToPackages';
import barChartCompsToPackages from './components/BarChart/compsToPackages';
import toggleSwitchPackages from './legacy/components/ToggleSwitch/compsToPackages';
import timePickerCompsToPackages from './components/TimePicker/compsToPackages';
import addressInputCompsToPackages from './components/AddressInput/compsToPackages';
import stylableLineCompsToPackages from './components/StylableLine/compsToPackages';
import ratingsInputCompsToPackages from './components/RatingsInput/compsToPackages';
import ratingsDisplayCompsToPackages from './components/RatingsDisplay/compsToPackages';
import videoPlayerCompsToPackages from './components/VideoPlayer/compsToPackages';
import lineShareButtonCompsToPackages from './components/LineShareButton/compsToPackages';
import captchaCompsToPackages from './components/Captcha/compsToPackages';
import musicPlayerCompsToPackages from './components/MusicPlayer/compsToPackages';
import stylableButtonCompsToPackages from './components/StylableButton/compsToPackages';
import progressBarCompsToPackages from './components/ProgressBar/compsToPackages';
import customElementCompsToPackages from './components/CustomElement/compsToPackages';

export default Object.assign(
  {},
  richTextBoxCompsToPackages,
  sliderCompsToPackages,
  searchBoxCompsToPackages,
  toggleSwitchPackages,
  timePickerCompsToPackages,
  addressInputCompsToPackages,
  stylableLineCompsToPackages,
  ratingsInputCompsToPackages,
  ratingsDisplayCompsToPackages,
  lineShareButtonCompsToPackages,
  captchaCompsToPackages,
  videoPlayerCompsToPackages,
  musicPlayerCompsToPackages,
  stylableButtonCompsToPackages,
  progressBarCompsToPackages,
  barChartCompsToPackages,
  customElementCompsToPackages,
);
